/* .va__heading{
    position: absolute;
    width: 821px;
    height: 150px;
    left: 310px;
    top: 4796px;

    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;

    text-align: center;
    letter-spacing: -0.04em;

    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
} */

.va__brand{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.va__brand div{
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.va__brand img{
    height: 86px;
    width: 86px;
}